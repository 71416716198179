// src/components/MediaSidebar.js
import React, { useEffect, useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  Button,
  MobileStepper,
  useTheme,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { AddFlex, CustomText } from "../../reusableStyles";
import {
  generateUUID,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../utils";
import { enqueueSnackbar } from "notistack";
import { callNonStoreApi } from "../../NonStoreApis";
import {
  RegisterCommentToDm,
  RegisterInstaDMKeywords,
} from "../../Api/ApiEndPoints";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import useWindowSize from "../../Hooks/useWindowSize";
import RightDrawerHeader from "../../Pages/OfferingsPages/OfferingCard/RightDrawerHeader";

const SidebarContainer = styled(AddFlex)`
  padding: 16px;
  flex-direction: "column";
  align-items: "center";
`;

const Container = styled(AddFlex)`
  margin: 0px auto;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  padding-top: 0px;
  align-items: left;
`;

const MediaImage = styled.img`
  aspect-ratio: 0.562;
  height: 10%;
  margin-bottom: 16px;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
`;

const Chip = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #e6e6e6;
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 14px;
`;

const ChipText = styled.span`
  margin-right: 4px;
`;

const ChipDelete = styled.button`
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  gap: 20px;
`;

const Input = styled.input`
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
`;
const AdditionalInput = styled.textarea`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const ConfirmButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

function SideBarSetup({
  selectedMedia,
  onClose,
  presetDetails,
  updateBackConfirmedData,
}) {
  console.log(presetDetails);
  const steps = [
    "What will start your DM Automation",
    "Enter the DM for comment",
    // "Select the Comment Replies",
  ];
  const defaultReplies = [
    "Sent DM, Please check",
    "Horray !! Sent you gift",
    "Thanks for message, Check your DM",
    "Sent DM, Please check",
    "Horray !! Sent you gift",
    "Thanks for message, Check your DM",
  ];
  const { isMobileView } = useWindowSize();
  const [inputValue, setInputValue] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const maxSteps = steps.length;
  const [enableAddButton, setEnableAddButton] = useState(false);

  useEffect(() => {
    if (presetDetails) {
      setKeywords(presetDetails.data.keywords || []);
      setMessageText(presetDetails.data.message || "");
    } else if (selectedMedia) {
      // Reset state when no preset details are available
      setKeywords([]);
      setMessageText("");
    }
    setActiveStep(1);
  }, [presetDetails, selectedMedia]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddKeyword = () => {
    if (inputValue.trim() !== "" && !keywords.includes(inputValue.trim())) {
      setKeywords([...keywords, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleDeleteKeyword = (keywordToDelete) => {
    setKeywords(keywords.filter((keyword) => keyword !== keywordToDelete));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddKeyword();
    }
  };

  const handleAdditionalTextChange = (e) => {
    setMessageText(e.target.value);
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      const result = await submitKeywordsApi(messageText, keywords);
    } catch (error) {
      enqueueSnackbar("Submission failed", {
        variant: "error",
      });
    }
    setIsSubmitting(false);
  };

  const submitKeywordsApi = async (text, keywords) => {
    try {
      if (isArrayNullOrUndefined(keywords)) {
        return enqueueSnackbar("Choose atleast one keyword", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom", // 'top', 'bottom'
            horizontal: "center", // 'left', 'center', 'right'
          },
          autoHideDuration: 1000,
        });
      }
      if (isNullOrUndefined(text) || text === "") {
        return enqueueSnackbar("Message cannot be empty", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom", // 'top', 'bottom'
            horizontal: "center", // 'left', 'center', 'right'
          },
          autoHideDuration: 1000,
        });
      }
      const response = await callNonStoreApi(RegisterCommentToDm, {
        mediaId: selectedMedia.id,
        isEdit: !isNullOrUndefined(presetDetails),
        data: {
          keywords: keywords,
          message: text,
        },
      });
      if (response.status === 200) {
        updateBackConfirmedData(response.data);
        setInputValue("");
        enqueueSnackbar("Trigger is created and Live", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom", // 'top', 'bottom'
            horizontal: "center", // 'left', 'center', 'right'
          },
          autoHideDuration: 3000,
        });
        return { success: true };
      } else
        return {
          success: false,
          message: "There is some error. Please contact admin",
        };
    } catch (error) {
      return {
        success: false,
        message: "There is some error. Please contact admin",
      };
    }
  };

  return (
    <Drawer
      anchor={isMobileView() ? "bottom" : "right"}
      open={Boolean(selectedMedia)}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: isMobileView() ? "100%" : "35%",
          borderRadius: isMobileView() && "24px 24px 0 0",
          height: isMobileView() ? "70%" : "100%",
        },
      }}
    >
      <RightDrawerHeader headerText={"Configure reply"} onClose={onClose} />
      {selectedMedia && (
        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            width: isMobileView() ? "100%" : "80%",
            padding: "20px",
            marginTop: "20px",
            position: "relative",
          }}
        >
          <CustomText
            color="black"
            fontWeight="500"
            style={{
              cursor: "auto",
              width: "max-content",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: isMobileView() ? "20px" : "40px",
            }}
          >
            Reply Post Comments to DM
          </CustomText>
          {/* <MediaImage
            src={
              selectedMedia.media_type === "VIDEO"
                ? selectedMedia.thumbnail_url
                : selectedMedia.media_url
            }
            alt="Selected Media"
          /> */}
          <CustomText color="black" style={{ cursor: "auto" }}>
            Step {activeStep} / {maxSteps}
          </CustomText>
          <MobileStepper
            variant="progress"
            steps={maxSteps + 1}
            position="static"
            activeStep={activeStep}
            sx={{
              paddingLeft: "0px",
              "& .MuiMobileStepper-progress": {
                backgroundColor: "lightGray", // Set the color for inactive dots (line color)
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: "green", // Filled progress bar color (blue)
              },
            }}
          />
          <CustomText
            color="black"
            fontWeight="500"
            style={{ cursor: "auto", marginTop: "5px", marginBottom: "20px" }}
          >
            {steps[activeStep - 1]}
          </CustomText>
          {activeStep === 1 ? (
            <AddFlex
              gap="15px"
              flexDirection="column"
              style={{
                border: "1px #d2d2d2 solid",
                padding: "20px",
                borderRadius: "6px",
              }}
            >
              <CustomText style={{ cursor: "auto" }}>
                Comments INCLUDE these keywords
              </CustomText>
              <InputContainer>
                <Input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Enter a keyword"
                />
                <Button
                  onClick={handleAddKeyword}
                  variant="contained"
                  disabled={isNullOrUndefined(inputValue) || inputValue === ""}
                >
                  Add
                </Button>
              </InputContainer>
              <ChipContainer>
                {keywords.map((keyword, index) => (
                  <Chip key={index}>
                    <ChipText>{keyword}</ChipText>
                    <ChipDelete onClick={() => handleDeleteKeyword(keyword)}>
                      ×
                    </ChipDelete>
                  </Chip>
                ))}
              </ChipContainer>
            </AddFlex>
          ) : activeStep === 2 ? (
            <div>
              <AdditionalInput
                type="text"
                value={messageText}
                onChange={handleAdditionalTextChange}
                placeholder="Enter the text that needs to be sent to user"
              />
            </div>
          ) : (
            <div>
              {defaultReplies.map((text) => (
                <AddFlex
                  style={{
                    padding: "10px",
                    marginTop: "8px",
                    border: "1px #c3c3c3 solid",
                    borderRadius: "4px",
                    color: "#666",
                  }}
                >
                  {text}
                </AddFlex>
              ))}
            </div>
          )}
          <Button
            onClick={handleBack}
            variant="outlined"
            sx={{
              marginTop: "30px",
              position: "absolute",
              left: "25px",
              display: activeStep === 1 ? "none" : "block",
            }}
          >
            Previous
          </Button>
          <Button
            onClick={activeStep < maxSteps ? handleNext : handleConfirm}
            variant="contained"
            disabled={
              activeStep < maxSteps
                ? false
                : !messageText || keywords.length === 0 || isSubmitting
            }
            sx={{ marginTop: "30px", position: "absolute", right: "25px" }}
          >
            {isSubmitting ? (
              <CircularProgress
                size={20}
                sx={{
                  color: "white", // Match the button's text color
                }}
              />
            ) : activeStep < maxSteps ? (
              "Continue"
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      )}
    </Drawer>
  );
}

export default SideBarSetup;

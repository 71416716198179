import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AddFlex, CustomText } from "../../reusableStyles";
import { callNonStoreApi } from "../../NonStoreApis";
import {
  DeleteInstaDMKeyword,
  FetchInstaDMKeywords,
  RegisterInstaDMKeywords,
} from "../../Api/ApiEndPoints";
import {
  generateUUID,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../utils";
import { IconButton } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { id } from "date-fns/locale";
import { enqueueSnackbar } from "notistack";

const Container = styled(AddFlex)`
  width: max-content;
  margin: 0px auto;
  flex-direction: column;
  gap: 20px;
  flex: 0.4;
  padding: 40px;
  padding-top: 0px;
  align-items: left;
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
`;

const Button = styled.button`
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
`;

const Chip = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 14px;
`;

const ChipText = styled.span`
  margin-right: 4px;
`;

const ChipDelete = styled.button`
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AdditionalInput = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 40px;
`;

const ConfirmButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const InstagramTab = () => {
  const [inputValue, setInputValue] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState(null);
  const [list, setList] = useState();
  const [entryId, setEntryId] = useState();

  useEffect(() => {
    const fetchInstaDMKeywords = async () => {
      try {
        const response = await callNonStoreApi(FetchInstaDMKeywords);
        updateList(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchInstaDMKeywords();
  }, []);

  const updateList = (response) => {
    if (isNullOrUndefined(response.data)) {
      setList(null);
    } else setList(response.data.data.keywordsData);
  };

  const submitKeywordsApi = async (text, keywords) => {
    try {
      if (isArrayNullOrUndefined(keywords)) {
        return enqueueSnackbar("Choose atleast one keyword", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom", // 'top', 'bottom'
            horizontal: "center", // 'left', 'center', 'right'
          },
          autoHideDuration: 1000,
        });
      }
      if (isNullOrUndefined(text) || text === "") {
        return enqueueSnackbar("Message cannot be empty", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom", // 'top', 'bottom'
            horizontal: "center", // 'left', 'center', 'right'
          },
          autoHideDuration: 1000,
        });
      }
      const response = await callNonStoreApi(RegisterInstaDMKeywords, {
        keywords: keywords,
        message: text,
        isEdit: !isNullOrUndefined(entryId),
        id: entryId ? entryId : generateUUID(),
      });
      if (response.status === 200) {
        updateList(response);
        setEntryId(null);
        setKeywords([]);
        setInputValue("");
        setMessageText("");
        enqueueSnackbar("Trigger is created and Live", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom", // 'top', 'bottom'
            horizontal: "center", // 'left', 'center', 'right'
          },
          autoHideDuration: 3000,
        });
        return { success: true };
      } else
        return {
          success: false,
          message: "There is some error. Please contact admin",
        };
    } catch (error) {
      return {
        success: false,
        message: "There is some error. Please contact admin",
      };
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddKeyword = () => {
    if (inputValue.trim() !== "" && !keywords.includes(inputValue.trim())) {
      setKeywords([...keywords, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleDeleteKeyword = (keywordToDelete) => {
    setKeywords(keywords.filter((keyword) => keyword !== keywordToDelete));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddKeyword();
    }
  };

  const handleAdditionalTextChange = (e) => {
    setMessageText(e.target.value);
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      const result = await submitKeywordsApi(messageText, keywords);
      setSubmitResult(result);
    } catch (error) {
      setSubmitResult({ success: false, message: "Submission failed" });
    }
    setIsSubmitting(false);
  };

  return (
    <AddFlex padding="20px">
      <Container>
        <CustomText color="black" fontSize="18px">
          New Trigger
        </CustomText>
        <CustomText color="black">
          Enter keywords in the input box below. When an Instagram user sends a
          message containing any of these keywords, the response you specify in
          the bottom text box will be automatically sent as a reply.
        </CustomText>
        <InputContainer>
          <Input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Enter a keyword"
          />
          <Button onClick={handleAddKeyword}>Add</Button>
        </InputContainer>
        <ChipContainer>
          {keywords.map((keyword, index) => (
            <Chip key={index}>
              <ChipText>{keyword}</ChipText>
              <ChipDelete onClick={() => handleDeleteKeyword(keyword)}>
                ×
              </ChipDelete>
            </Chip>
          ))}
        </ChipContainer>
        <AdditionalInput
          type="text"
          value={messageText}
          onChange={handleAdditionalTextChange}
          placeholder="Enter the text that needs to be sent to user"
        />
        <ConfirmButton onClick={handleConfirm} disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Confirm"}
        </ConfirmButton>
        {/* {submitResult && (
          <div>
            {submitResult.success ? (
              <p style={{ color: "green" }}>{submitResult.message}</p>
            ) : (
              <p style={{ color: "red" }}>{submitResult.message}</p>
            )}
          </div>
        )} */}
      </Container>
      <AddFlex
        style={{
          flex: 0.5,
          gap: "20px",
        }}
        flexDirection="column"
      >
        <CustomText color="black" fontSize="18px">
          Created Triggers
        </CustomText>
        {list &&
          list.map((item, index) => {
            return (
              <AddFlex
                width="100%"
                style={{ boxShadow: "0 0 4px 2px rgba(0,0,0,0.1)" }}
              >
                <AddFlex
                  flexDirection="column"
                  gap="10px"
                  padding="10px"
                  style={{ flexGrow: 1 }}
                >
                  <CustomText color="black">
                    Keywords : {item.keywords.join(",")}
                  </CustomText>
                  <CustomText color="black">
                    Message : {item.message}
                  </CustomText>
                </AddFlex>
                <IconButton
                  onClick={async () => {
                    setKeywords(item.keywords);
                    setMessageText(item.message);
                    setEntryId(item.id);
                  }}
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={async () => {
                    const response = await callNonStoreApi(
                      DeleteInstaDMKeyword,
                      { id: item.id }
                    );
                    updateList(response);
                  }}
                >
                  <ClearOutlinedIcon />
                </IconButton>
              </AddFlex>
            );
          })}
        {isArrayNullOrUndefined(list) && (
          <CustomText>No triggers have been set</CustomText>
        )}
      </AddFlex>
    </AddFlex>
  );
};

export default InstagramTab;

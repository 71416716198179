import React, { useState } from "react";
import { AddFlex, InputLabel, SecondaryActionButton } from "../reusableStyles";
import SectionHeader from "./Components/SectionHeader";
import TextEditor from "./TextEditor";
import { Backdrop } from "@mui/material";
import GalleryComponent from "./GalleryComponent";
import SuccessChip from "../Integrations/SuccessChip";
import { Delete } from "@mui/icons-material";
import GalleryComponentWrapper from "../GalleryComponentWrapper";

function ShareMediaAfterBooking({ handleSetOffering, sharableMedia }) {
  const [openGallery, setOpenGallery] = useState();
  return (
    <AddFlex gap="20px" flexDirection="column">
      <SectionHeader
        headerText={"Send instructions after booking"}
        subHeaderText={"Add text or PDF (Sent via WhatsApp)"}
      />
      <div>
        <TextEditor
          label={"Instructions"}
          value={sharableMedia?.instructions}
          getTextEditorInput={(text) => {
            handleSetOffering("sharableMedia.instructions", text);
          }}
        />
      </div>
      <div>
        <InputLabel>Add a PDF (optional)</InputLabel>
        <SecondaryActionButton
          onClick={() => {
            setOpenGallery(true);
          }}
          style={{ width: "max-content", marginTop: "8px" }}
        >
          Add Document
        </SecondaryActionButton>
        {sharableMedia?.file && (
          <SuccessChip
            style={{ margin: "10px 0", width: "max-content" }}
            successChipText={sharableMedia.file.name}
            showIcon={true}
            disableAction={true}
            icon={
              <Delete
                sx={{ color: "#047647", fontSize: "16px", cursor: "pointer" }}
                onClick={() => {
                  handleSetOffering("sharableMedia.file", null);
                }}
              />
            }
          />
        )}
      </div>
      <GalleryComponentWrapper
        openGallery={openGallery}
        setOpenGallery={setOpenGallery}
        files={null}
        handleGetFiles={(_, file) => {
          handleSetOffering("sharableMedia.file", {
            name: file[0].name,
            src: file[0].src,
          });
        }}
        isMultiple={false}
      />
    </AddFlex>
  );
}

export default ShareMediaAfterBooking;

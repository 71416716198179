import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import useUsernameAvailabilityCheck from "../CustomHooks/useUsernameAvailabilityCheck";
import { AddFlex, InputLabel } from "../reusableStyles";
import ProfileInfoUsernameAndURL from "./ProfileInfoUsernameAndURL";
import ProfileInfoHeader from "./ProfileInfoHeader";
import UserBio from "./UserBio";
import PhotoUpload from "./PhotoUpload";
import { useDispatch } from "react-redux";
import { getApiCallParams } from "../utils";
import { usersApiCall } from "../Store/userSlice";
import { UpdateUser } from "../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import AboutSection from "./AboutSection";
import BrandBadgeVisibility from "./BrandBadge";

function ProfileInfo() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { apiUserData } = useSelector((state) => state.user);
  const [isUpdating, setIsUpdating] = useState(false);
  const [userData, setUserData] = useState(apiUserData);
  const [username, setUsername] = useState(apiUserData.userName);
  const debounceTimer = useRef();

  const handleSetUserData = (key, value) => {
    setUserData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleAbout = (text) => {
    setUserData((prev) => ({
      ...prev,
      UserAdditionalDetails: {
        ...prev.UserAdditionalDetails,
        about: text,
      },
    }));
  };

  const {
    isAvailable,
    loading,
    setIsAvailable,
    isErr,
    errMsg,
    usernameSuggestions,
  } = useUsernameAvailabilityCheck(apiUserData.userName, username);

  const handleChangePhotoURL = (URL) => {
    handleSetUserData("photoURL", URL);
  };

  const handleChangeBio = (text) => {
    handleSetUserData("Bio", text);
  };

  const handleChangeName = (text) => {
    handleSetUserData("Name", text);
  };

  const handleChangeUsername = (text) => {
    setUsername(text);
  };

  const handleUpdateProfile = async () => {
    let _userData = userData;
    if (loading) return;
    if (JSON.stringify(apiUserData) === JSON.stringify(userData)) {
      return setIsUpdating(false);
    }
    if (userData.userName !== apiUserData.userName) {
      if (isAvailable) {
        _userData = {
          ...userData,
          userName: userData.userName.toLowerCase(),
        };
      } else {
        //snackbar for not available username

        enqueueSnackbar(
          "This username is already taken, please try another one",
          {
            variant: "error",
            hideIconVariant: true,
            autoHideDuration: 6000,
          }
        );
        return;
      }
    }

    setIsUpdating(true);
    dispatch(usersApiCall(getApiCallParams(UpdateUser, _userData))).then(
      (actionResult) => {
        if (actionResult.meta.requestStatus === "fulfilled") {
          // Handle success

          enqueueSnackbar("Profile updated successfully", {
            variant: "success",
            hideIconVariant: true,
            autoHideDuration: 1500,
          });
          setIsUpdating(false);
          setIsAvailable(false);
        } else {
          // Handle failure
          enqueueSnackbar("Something went wrong please try again", {
            variant: "error",
            hideIconVariant: true,
            autoHideDuration: 1500,
          });
          console.error("Action failed", actionResult.error);
        }
      }
    );
  };

  const debounce = (callback, delay) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(callback, delay);
  };

  useEffect(() => {
    debounce(handleUpdateProfile, 1500);
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [userData]);

  useEffect(() => {
    console.log(isAvailable, username);
    if (isAvailable) {
      handleSetUserData("userName", username);
    }
  }, [username, isAvailable]);

  return (
    <AddFlex margin={"30px 0"} flexDirection="column">
      <ProfileInfoHeader
        isUpdating={isUpdating}
        onSubmit={handleUpdateProfile}
      />
      <AddFlex flexDirection="column">
        <ProfileInfoUsernameAndURL
          initialUsername={apiUserData.userName}
          username={username}
          name={userData.Name}
          isUsernameAvailable={isAvailable}
          handleChangeName={handleChangeName}
          isUsernameAvailabilityChecking={loading}
          errMessage={errMsg}
          isErr={isErr}
          usernameSuggestions={usernameSuggestions}
          handleChangeUsername={handleChangeUsername}
        />
        <InputLabel>Profile Image</InputLabel>
        <PhotoUpload
          photoURL={userData.photoURL}
          handleGetFile={handleChangePhotoURL}
          isProfileImage={true}
        />
        <UserBio bio={userData.Bio} handleBioChange={handleChangeBio} />
        <BrandBadgeVisibility />
      </AddFlex>
    </AddFlex>
  );
}
export default ProfileInfo;

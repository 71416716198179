// src/store/instagramSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isNullOrUndefined } from "../utils";

const ACCESS_TOKEN =
  "IGQWRQQnhMTks2eXFJUTJqRFh3ZAHJVUTlaVzVSMVJLWDQycXhGenVLaTRISy10enFpREs2UzJENUhXRWx5T2gtX095UUFXb2sxNzBWaFRGcmdHcVB5QUhzdkFmX1BfcXFyY3ZAiamxmQ2R0ZAwZDZD";
const USER_ID = "17841459772097618";

// Create an async thunk for fetching Instagram media
export const fetchInstagramMedia = createAsyncThunk(
  "instagram/fetchMedia",
  async (nextPageOffset) => {
    const response = await axios.get(
      `https://graph.instagram.com/${USER_ID}/media?fields=id,media_type,media_url,thumbnail_url&access_token=${ACCESS_TOKEN}&limit=20${
        !isNullOrUndefined(nextPageOffset) ? `&after=${nextPageOffset}` : ""
      }`
    );
    return response.data;
  }
);

const instagramSlice = createSlice({
  name: "instagram",
  initialState: {
    posts: [],
    reels: [],
    comments: {}, // { mediaId: [{ keyword, reply }] }
    loading: false,
    error: null,
  },
  reducers: {
    saveKeywordReply(state, action) {
      const { mediaId, keyword, reply } = action.payload;
      if (!state.comments[mediaId]) {
        state.comments[mediaId] = [];
      }
      state.comments[mediaId].push({ keyword, reply });
    },
    clearComments(state, action) {
      delete state.comments[action.payload];
    },
    clearPosts(state) {
      state.posts = [];
      state.reels = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstagramMedia.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInstagramMedia.fulfilled, (state, action) => {
        const newPosts = action.payload.data || [];
        const existingPostIds = new Set(state.posts.map((post) => post.id));

        // Filter out duplicates
        const uniquePosts = newPosts.filter(
          (post) => !existingPostIds.has(post.id)
        );

        state.posts.push(...uniquePosts);
        state.loading = false;
      })
      .addCase(fetchInstagramMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions
export const { saveKeywordReply, clearComments, clearPosts } =
  instagramSlice.actions;

// Export the reducer
export default instagramSlice.reducer;

import { addDays, addMonths, format, isBefore, subDays } from "date-fns";
import { isArrayNullOrUndefined, isNullOrUndefined } from "../../utils";
import { DURATION_ENUMS } from "../../CreateOffering/Components/SubscriptionPlanForm";

export const calculatePlanEndDate = (
  saleDate,
  durationType,
  duration,
  endDate
) => {
  if (endDate) {
    return format(new Date(endDate), "dd MMM yyyy");
  }
  return format(
    new Date(addMonths(new Date(saleDate), duration)),
    "dd MMM yyyy"
  );
};

export const SUBSCRIPTION_STATES = {
  ACTIVE: "active",
  TO_RENEW: "to-renew",
  INACTIVE: "inactive",
};

export const getSubscriptionState = (planEndDate) => {
  const endDate = new Date(planEndDate);
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  const fifteenDaysAgo = subDays(today, 15);

  if (isBefore(endDate, fifteenDaysAgo)) {
    // if plan end date is more than 15 days in the past
    return SUBSCRIPTION_STATES.INACTIVE;
  } else if (isBefore(endDate, today)) {
    // if plan end date is less than or equal to 15 days in the past
    return SUBSCRIPTION_STATES.TO_RENEW;
  } else {
    // Else plan is active
    return SUBSCRIPTION_STATES.ACTIVE;
  }
};

const parseSubscriptionBookings = (sales) => {
  const obj = {};
  if (isArrayNullOrUndefined(sales)) return [];
  sales.forEach((sale) => {
    if (!obj[sale.bookingCustomerId]) {
      obj[sale.bookingCustomerId] = [];
    }
    obj[sale.bookingCustomerId].push(sale);
  });
  return Object.values(obj);
};

export const parseSubscriptionsSaleData = (sales) => {
  // const parsedBookingData = parseSubscriptionBookings(sales);
  const plansMap = {};
  const sortedSales = Object.values(sales).sort((a, b) => {
    return new Date(b[0].saleDate) - new Date(a[0].saleDate);
  });
  const SubscriptionStates = {
    0: sortedSales.map((sale) => {
      return sale[0].subscriptionId;
    }), //all bookings
    1: [], // active bookings
    2: [], // torenew bookings
    3: [], // inactive bookings
  };
  sortedSales.forEach((booking, index) => {
    let ticketName = booking[0].ticketName;
    if (isNullOrUndefined(plansMap[ticketName])) {
      plansMap[ticketName] = 1;
    } else {
      plansMap[ticketName] = plansMap[ticketName] + 1;
    }
    const planState = getSubscriptionState(
      calculatePlanEndDate(
        booking[0].subscriptionStartDate,
        booking[0].durationType,
        booking[0].duration,
        booking[0].subscriptionEndDate
      )
    );
    if (planState === SUBSCRIPTION_STATES.ACTIVE) {
      SubscriptionStates[1].push(booking[0].subscriptionId);
    } else if (planState === SUBSCRIPTION_STATES.TO_RENEW) {
      SubscriptionStates[2].push(booking[0].subscriptionId);
    } else {
      SubscriptionStates[3].push(booking[0].subscriptionId);
    }
  });
  return { SubscriptionStates, plansMap };
};

export const subscriptionSearchFilter = (allSales, sales, searchText) => {
  if (isNullOrUndefined(searchText) || searchText.length === 0) return null;
  if (isArrayNullOrUndefined(sales)) return [];
  console.log(sales);
  return sales.filter((sale) => {
    console.log(allSales[sale][0].name === undefined ? allSales[sale][0] : "");
    return (
      allSales[sale][0].number?.includes(searchText) ||
      allSales[sale][0].name.toLowerCase().includes(searchText)
    );
  });
};

export const subscriptionPlanNamesFilter = (allPlans, sales, plans) => {
  if (isArrayNullOrUndefined(plans)) return null;
  if (isArrayNullOrUndefined(sales)) return [];
  return sales.filter((sale) => plans.includes(allPlans[sale][0].ticketName));
};

export const getSubscriptionDuration = (sale) => {
  let endDate = getSubscriptionEndDate(sale);
  if (sale.durationType === DURATION_ENUMS.demo) {
    return "Demo Session";
  }
  if (sale.durationType === DURATION_ENUMS.sessions) {
    return `${sale.duration} ${sale.duration === 1 ? "Session" : "Sessions"}`;
  }
  if (isNullOrUndefined(sale.durationType) || sale.durationType === "months") {
    return `${format(
      new Date(sale.subscriptionStartDate),
      "dd MMM''yy"
    )} - ${format(new Date(endDate), "dd MMM''yy")}`;
  }
};

export const getSubscriptionEndDate = (sale) => {
  if (sale.subscriptionEndDate) {
    return sale.subscriptionEndDate;
  } else {
    return addMonths(new Date(sale.subscriptionStartDate), sale.duration);
  }
};

import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import SectionHeader from "./SectionHeader";
import call from "../../ImageAssets/call_logo.png";
import google from "../../ImageAssets/Google_logo.png";
import payments from "../../ImageAssets/payments.png";
import whatsapp from "../../ImageAssets/whatsaap_logo_merged.png";
import form from "../../ImageAssets/add_notes.png";
import useWindowSize from "../../Hooks/useWindowSize";
import PageGoalCard from "./PageGoalCard";
import { CTA_Button_Types, FeaturesKeys } from "../../utils";

export const pageGoals = [
  {
    key: "collect_payments",
    image: payments,
    name: "Register Now",
    actionName: "Collect Payments",
    type: CTA_Button_Types.PAYMENTS,
  },
  {
    key: "dm_to_whatsapp",
    image: whatsapp,
    name: "DM to Whatsapp",
    actionName: "DM to Whatsapp",
    type: CTA_Button_Types.WHATSAPP,
  },
  {
    key: "schedule_a_meet",
    image: google,
    name: "Schedule a meet",
    actionName: "Schedule a meet",
    type: CTA_Button_Types.GOOGLEMEET,
  },
  {
    key: "schedule_a_call_back_phone",
    image: call,
    name: "Schedule a Call back",
    actionName: "Schedule a Call back",
    type: CTA_Button_Types.CALL,
  },
  {
    key: "fill_a_form",
    image: form,
    name: "Fill a form",
    actionName: "Fill a form",
    type: CTA_Button_Types.FORM,
  },
];

function PageGoals({ offering, errors, handleSetOffering, pageType }) {
  const { isMobileView } = useWindowSize();
  const OneonOnePageGoals = [
    CTA_Button_Types.PAYMENTS,
    CTA_Button_Types.FORM,
    CTA_Button_Types.WHATSAPP,
  ];
  // const [buttons, setButtons] = useState(offering.ctas);

  const onSelect = (cta_data) => {
    if (offering.ctas.some((element) => element.type === cta_data.type)) {
      const index = offering.ctas.indexOf(
        offering.ctas.filter((element) => element.type === cta_data.type)[0]
      );
      handleSetOffering(`ctas.${index}`, cta_data);
      return { message: "success" };
    } else {
      if (offering.ctas.length === 2) {
        return { message: "error", error: "You Can Add Only Upto 2 CTAs" };
      }
      if (cta_data.type === CTA_Button_Types.PAYMENTS) {
        const ctas = [cta_data, ...offering.ctas];
        handleSetOffering("ctas", ctas);
        return { message: "success" };
      }
      handleSetOffering(`ctas.push`, cta_data);
      return { message: "success" };
    }
  };

  const isOneonOnePageType = () => {
    return pageType === FeaturesKeys.oneonone.name;
  };

  const handleRemoveCTA = (button) => {
    const index = offering.ctas.indexOf(
      offering.ctas.filter((element) => element.type === button.type)[0]
    );
    handleSetOffering(`ctas.delete.${index}`, null);
  };

  const reverseButtons = () => {
    const temp = [...offering.ctas];
    const newCtas = [...temp.reverse()];
    handleSetOffering(`ctas`, newCtas);
  };

  const shouldAddInOneonOne = (pageGoal) => {
    return OneonOnePageGoals.includes(pageGoal.type);
  };

  return (
    <AddFlex flexDirection="column">
      <SectionHeader
        headerText={"Page Goals"}
        required
        subHeaderText={
          <>
            Add up to <b>2</b> Call to Action buttons !
          </>
        }
      />
      <CustomText color="red" margin="8px 0 0 0" fontSize="14px">
        {errors["ctas"]?.err}
      </CustomText>

      <AddFlex margin="28px 0 0 0" wrap="wrap">
        {pageGoals
          .filter(
            (pageGoal) => !isOneonOnePageType() || shouldAddInOneonOne(pageGoal)
          )
          .map((pageGoal) => (
            <PageGoalCard
              isMobileView={isMobileView()}
              pageGoal={pageGoal}
              isSelected={offering.ctas.some(
                (element) => element.type === pageGoal.type
              )}
              button_data={
                offering.ctas.filter(
                  (button) => button.type === pageGoal.type
                )[0]
              }
              onSelect={onSelect}
              key={pageGoal.key}
              addedButtons={offering.ctas.length}
              handleRemoveCTA={handleRemoveCTA}
            />
          ))}
      </AddFlex>
    </AddFlex>
  );
}

export default PageGoals;

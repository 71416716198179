import React, { useEffect } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import useWindowSize from "../Hooks/useWindowSize";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import PublicProfileViewLink from "./PublicProfileViewLink";
import { primaryActionColor } from "../constants";
import { useLayout } from "../CustomHooks.js/LayoutContext";
import { isNullOrUndefined } from "../utils";
import HelpAndSupport from "./HelpAndSupport";

const HamburgerButton = styled(MenuIcon)`
  margin-right: 10px;
`;

const TopBannerWrapper = styled(AddFlex)`
  overflow: hidden;
  padding: 30px 48px 10px 48px;
  flex-shrink: 0;
  /* box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.2); */
  @media screen and (max-width: 1000px) {
    top: 0;
    left: 0;
    width: 100vw;
    padding: 20px;
  }
  background-color: white;
  border-bottom: ${(props) => props.showBorder && "1px solid #ececec"};
`;

const PageTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  /* margin-left: 20px; */
  color: #101828;
  letter-spacing: -2%;
`;

function TopBanner({ pageTitle, showBackArrow, invertSideBarVisibility }) {
  const { windowSize, isMobileView } = useWindowSize();
  const location = useLocation();
  const { apiUserData } = useSelector((state) => state.user);
  const {
    layoutState: { Component, props, children },
    clearLayoutComponent,
  } = useLayout();
  const navigate = useNavigate();

  const getComponent = () => {
    if (isNullOrUndefined(Component)) {
      return <HelpAndSupport />;
    } else {
      if (isNullOrUndefined(children)) {
        return (
          <AddFlex gap="10px">
            <HelpAndSupport />
            <Component {...props} />
          </AddFlex>
        );
      }
      return (
        <AddFlex gap="15px" alignItems="center">
          <HelpAndSupport />
          <Component {...props}>{children}</Component>
        </AddFlex>
      );
    }
  };

  useEffect(() => {
    clearLayoutComponent();
  }, [location.pathname]);
  return (
    <TopBannerWrapper
      alignItems="center"
      showBorder={pageTitle === "Dashboard"}
      // height={windowSize.screenHeight * (isMobileView() ? 0.05 : 0.05) + "px"}
    >
      <HamburgerButton
        sx={{ display: !isMobileView() && "none" }}
        onClick={() => invertSideBarVisibility()}
      />
      {pageTitle === "Dashboard" ? (
        <AddFlex
          flexDirection="row"
          justify="space-between"
          alignItems="center"
          style={{ width: "100%" }}
        >
          <CustomText fontSize="30px" color="black" fontWeight="600">
            Hey, {apiUserData.Name}
          </CustomText>
          <PublicProfileViewLink />
        </AddFlex>
      ) : (
        <AddFlex flexDirection="row" alignItems="center" width="100%">
          {showBackArrow && (
            <ArrowBackIcon
              sx={{
                cursor: "pointer",
                width: "max-content",
                padding: "0 8px 0 0px",
                marginLeft: "-8px",
              }}
              onClick={() => {
                navigate(-1);
              }}
            />
          )}
          <AddFlex gap="4px" flexDirection="column">
            <PageTitle>{pageTitle}</PageTitle>
            <div
              style={{
                height: "3px",
                width: "36px",
                marginLeft: "1.5px",
                borderRadius: "25px",
                backgroundColor: primaryActionColor,
              }}
            ></div>
          </AddFlex>
          <Gap />
          {getComponent()}
        </AddFlex>
      )}
    </TopBannerWrapper>
  );
}

export default TopBanner;

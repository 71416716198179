import React, { useEffect, useState } from "react";
import Tabs from "../Tabs";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import {
  FeaturesKeys,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import { AddFlex } from "../reusableStyles";
import { Outlet, useLocation, useNavigate } from "react-router";
import { getOfferingAllSalesRoutes, getOfferingReportsRoutes } from "../routes";
import { useDispatch } from "react-redux";
import { setReportsTabPathAction } from "../Store/appTempSlice";
import useWindowSize from "../Hooks/useWindowSize";

function AllSalesTab() {
  const { isMobileView } = useWindowSize();
  const dispatch = useDispatch();
  const selectedReportsTabPath = useSelector(
    (state) => state.appTemp.data.reportsTabPath
  );

  const userFeatures = useSelector(
    (state) => state.userFeatures.userFeatures?.features
  );
  const location = useLocation();
  const [offerings, setOfferings] = useState([]);

  const navigate = useNavigate();
  const getUserCreatedOfferingTabs = () => {
    if (isArrayNullOrUndefined(userFeatures)) return [];
    const offerings = [];
    Object.keys(userFeatures).forEach((featureId) => {
      const name = Object.values(FeaturesKeys).find(
        (item) => item.id === featureId
      ).name;
      if (name !== FeaturesKeys.testimonial.name)
        offerings.push(
          getOfferingAllSalesRoutes().find((item) => item.name === name)
        );
    });
    return offerings;
  };

  useEffect(() => {
    const offerings = getUserCreatedOfferingTabs();
    setOfferings(offerings);
    if (!isArrayNullOrUndefined(offerings)) {
      if (selectedReportsTabPath === location.pathname) return;
      navigate(
        isNullOrUndefined(selectedReportsTabPath)
          ? offerings[0].path
          : selectedReportsTabPath
      );
      navigate(offerings[0].path);
    }
  }, [location.pathname]);

  const getSelectedTabIndex = () => {
    const paths = window.location.pathname.split("/");
    const currentPath = paths[paths.length - 1];
    const index = offerings.findIndex((item) => item.name === currentPath);
    return index < 0 ? 0 : index;
  };

  return (
    <AddFlex
      flexDirection="column"
      style={{
        overflow: "hidden",
        height: "100%",
      }}
    >
      <AddFlex
        padding={isMobileView() ? "0 20px" : "0px 48px 15px 28px"}
        justify="space-between"
        marginBottom="0px"
        flexDirection="column"
        style={{ borderBottom: "1px solid #ececec" }}
      >
        <Tabs
          tabs={offerings}
          margin="0 0 0 20px"
          borderBottom={"none"}
          selectedTabIndex={getSelectedTabIndex()}
          setSelectedTabIndex={(index, tab) => {
            dispatch(setReportsTabPathAction(tab.path));
            navigate(tab.path);
          }}
        />
      </AddFlex>
      <AddFlex
        flexDirection="column"
        style={{ flexGrow: 1, overflow: "scroll" }}
      >
        <Outlet />
      </AddFlex>
    </AddFlex>
  );
}

export default AllSalesTab;

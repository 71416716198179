// src/components/InstaDm.js
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Box,
  CircularProgress,
  Drawer,
  Typography,
  Button,
} from "@mui/material";
import styled from "styled-components";
import {
  fetchInstagramMedia,
  saveKeywordReply,
  clearPosts,
} from "../../Store/instagramSlice";
import SideBarSetup from "./SideBarSetup";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchInstaCommentToDmPosts } from "../../Api/ApiEndPoints";
import BoltIcon from "@mui/icons-material/Bolt";
import { primaryActionColor } from "../../constants";
import { AddFlex, Gap, PrimaryActionButton } from "../../reusableStyles";
import { BoxedTabs } from "../../Pages/OfferingsPages/OfferingPagesTabHeader";
import useWindowSize from "../../Hooks/useWindowSize";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const PostGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  @media screen and (max-width: 1000px) {
    gap: 0px;
  }
`;

const PostItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: ${(props) => (props.type === "VIDEO" ? "200px" : "200px")};
  height: ${(props) =>
    props.type === "VIDEO" ? "calc(200px * (16 / 9))" : "200px"};
  background: #f9f9f9;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 1000px) {
    width: ${(props) => (props.type === "VIDEO" ? "33.33%" : "33.34vw")};
    height: ${(props) =>
      props.type === "VIDEO" ? "calc(33.34% * (16 / 9))" : "33.34vw"};
    border-radius: 0px;
  }
`;

const PostImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

function InstaDm() {
  const dispatch = useDispatch();
  const { isMobileView } = useWindowSize();
  const { posts, loading, comments } = useSelector((state) => state.instagram);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [selectedMedia, setSelectedMedia] = React.useState(null);
  const [presetPosts, setPresetPosts] = useState();

  useEffect(() => {
    dispatch(clearPosts()); // Clear posts when the component mounts
    dispatch(fetchInstagramMedia()); // Fetch initial data

    const fetchPresetPosts = async () => {
      const response = await callNonStoreApi(FetchInstaCommentToDmPosts);
      if (response.status === 200) {
        setPresetPosts(response.data);
      }
    };
    fetchPresetPosts();
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(clearPosts()); // Clear existing posts
    dispatch(fetchInstagramMedia()); // Fetch new data
  };

  const handleSaveMapping = (mapping) => {
    dispatch(saveKeywordReply(mapping));
  };

  return (
    <AddFlex
      flexDirection="column"
      padding={isMobileView() ? "0 0px" : "0 48px"}
    >
      <AddFlex alignItems="center" margin={isMobileView() ? "20px" : "20px 0"}>
        <BoxedTabs
          tabs={["Posts", "Reels"]}
          selectedTabIndex={tabIndex}
          setSelectedTabIndex={(index) => {
            setTabIndex(index);
          }}
        />
        <Gap />
        <PrimaryActionButton
          onClick={handleRefresh}
          variant="contained"
          color="primary"
        >
          Refresh
        </PrimaryActionButton>
      </AddFlex>
      <PostGrid>
        {posts
          .filter((item) =>
            tabIndex === 0
              ? item.media_type !== "VIDEO"
              : item.media_type === "VIDEO"
          )
          .map((media) => (
            <PostItem
              type={media.media_type}
              key={media.id}
              onClick={() => setSelectedMedia(media)}
            >
              {presetPosts && presetPosts[media.id] && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: "111",
                    right: "10px",
                    top: "10px",
                    backgroundColor: "#000000e6",
                    borderRadius: "6px",
                    display:
                      presetPosts && presetPosts[media.id] ? "block" : "none",
                  }}
                >
                  <AddFlex
                    justify="center"
                    alignItems="center"
                    color="white"
                    style={{ color: "white", padding: "3px 10px" }}
                  >
                    <BoltIcon
                      sx={{
                        color: "white",
                      }}
                    />
                    {presetPosts[media.id].commentCount}
                  </AddFlex>
                </div>
              )}
              <PostImage
                src={
                  media.media_type === "VIDEO"
                    ? media.thumbnail_url
                    : media.media_url
                }
                alt="Instagram Media"
              />
            </PostItem>
          ))}
      </PostGrid>
      {loading && <CircularProgress />}
      <SideBarSetup
        selectedMedia={selectedMedia}
        presetDetails={
          selectedMedia && presetPosts && presetPosts[selectedMedia.id]
        }
        onClose={() => setSelectedMedia(null)}
        onSaveMapping={handleSaveMapping}
        existingComments={comments[selectedMedia?.id]}
        updateBackConfirmedData={(data) => {
          setPresetPosts((prevState) => ({
            ...prevState, // Spread the existing state
            [data.mediaId]: {
              // Spread the existing `id1` data
              ...data, // Merge the new data into `id1`
            },
          }));
        }}
      />
    </AddFlex>
  );
}

export default InstaDm;

import React from "react";
import { AddFlex, ContentHeader, CustomText } from "../reusableStyles";
import { Divider } from "@mui/material";
import { BoxedTabs } from "../Pages/OfferingsPages/OfferingPagesTabHeader";
import { useSelector } from "react-redux";
import { PGCharges } from "../utils";

function PaymentGatewayCharges({
  handleChangeWhoPaysConvenience,
  getWhoPaysConvenience,
  style,
  padding,
}) {
  const { UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );
  const getInrConvenience = () => {
    if (UserAdditionalDetails.paymentProvider === "growezy") {
      return PGCharges.Growezy.INR;
    } else return PGCharges.Razorpay.INR;
  };

  const getUsdConvenience = () => {
    if (UserAdditionalDetails.paymentProvider === "growezy") {
      return PGCharges.Growezy.USD;
    } else return PGCharges.Razorpay.USD;
  };

  return (
    <AddFlex
      padding={padding ? padding : "20px"}
      gap="20px"
      borderRadius="12px"
      style={style ? style : { height: "max-content", width: "100%" }}
      flexDirection="column"
      backgroundColor="white"
    >
      <AddFlex flexDirection="column">
        <ContentHeader fontSize="16px" fontWeight="500">
          Choose who pays the payment gateway fees.
        </ContentHeader>
        <CustomText>This setting can be changed for each offering.</CustomText>
      </AddFlex>
      <AddFlex margin="10px 0 0 0" gap="15px" wrap="wrap" alignItems="center">
        <CustomText color="black" style={{ width: "57%" }}>
          For INR payments ({(getInrConvenience() * 100).toFixed(2)}%)
        </CustomText>
        <BoxedTabs
          tabs={["Me", "My Clients"]}
          selectedTabIndex={getWhoPaysConvenience(0)}
          setSelectedTabIndex={(index, elem) => {
            handleChangeWhoPaysConvenience(0, index === 0 ? true : false);
          }}
        />
      </AddFlex>
      <AddFlex gap="15px" alignItems="center" wrap="wrap">
        <CustomText color="black" style={{ width: "57%" }}>
          For Non-INR payments ({(getUsdConvenience() * 100).toFixed(2)}%)
        </CustomText>
        <BoxedTabs
          tabs={["Me", "My Clients"]}
          selectedTabIndex={getWhoPaysConvenience(1)}
          setSelectedTabIndex={(index, elem) => {
            handleChangeWhoPaysConvenience(1, index === 0 ? true : false);
          }}
        />
      </AddFlex>
    </AddFlex>
  );
}

export default PaymentGatewayCharges;

export const primaryActionColor = "#2e5dcd";
export const primaryActionColorRGBA = "rgba(46,93,205,1)";
export const primaryActionColorLight = "#F3F7FE";

export const secondaryActionColor = "#EDF4FF";

export const inputHeight = "40px";
export const inputPadding = "5px 15px";
export const actionButtomBroderRadius = "5px";
export const brandYellow = "#f4a340";
export const ADMIN = "/admin/";
export const primaryTextColor = "#282C44";
export const headerTextColor = "#344054";
export const headingSectionLeftBorderColor = "#DB7F10";

export const LeadSource = {
  WhatsappDm: "WhatsappDm",
  LeadForm: "LeadFrom",
  GoogleMeet: "GoogleMeet",
  PhoneCall: "PhoneCall",
  AddedByYou: "AddedByYou",
};

export const LeadState = {
  NewLead: "NewLead",
  InProgress: "InProgress",
  Payments: "Payments",
  Closed: "Closed",
  Delete: "LeadDelete",
};

export const LeadActions = {
  //These below will trigger LeadState change
  MarkInProgress: "MarkInProgress",
  SendPaymentLink: "SendPaymentLink",
  SendPaymentReminder: "SendPaymentReminder",
  MarkAsWon: "MarkAsWon",
  MarkAsLost: "MarkAsLost",
  DeleteLead: "DeleteLead",
  //These below wont change LeadState.
  ScheduleGoogleMeet: "ScheduleGoogleMeet",
  SchedulePhoneCall: "SchedulePhoneCall",
  TransferLead: "TransferLead",
  RescheduleMeet: "RescheduleMeet",
  RescheduleCall: "RescheduleCall",
  NoteAdded: "NoteAdded",
  NoteUpdate: "NoteUpdate",
  NoteDelete: "NoteDelete",
};

export const PLATFORMS_DATA = [
  {
    platform: "Instagram",
    icon: "https://www.freepnglogos.com/uploads/logo-ig-png/logo-ig-png-instagram-logo-camel-productions-website-25.png",
    title: "Instagram profile url",
    placeholder: "Ex:https://www.instagram.com/growezy/",
    inputText:
      "Please enter the user profile url. Ex:- https://www.instagram.com/growezy",
    status: true,
    error: false,
    reg: /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/[a-zA-Z0-9_\.]{1,30}\/?$/,
    saved: false,
    isLoading: false,
    showDefault: true,
  },
  {
    platform: "YouTube",
    icon: "https://www.freepnglogos.com/uploads/youtube-play-red-logo-png-transparent-background-6.png",
    title: "Youtube profile url",
    placeholder: "Profile Url",
    inputText: "Please enter the complete youtube profile links",
    status: true,
    error: false,
    reg: /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:user\/|channel\/|c\/|@)?([a-zA-Z0-9_-]{1,})$/,
    saved: false,
    isLoading: false,
    showDefault: true,
  },
  {
    platform: "WhatsApp",
    icon: "https://www.freepnglogos.com/uploads/whatsapp-logo-png-hd-2.png",
    title: "Whatsapp number",
    placeholder: "Enter you number",
    status: true,
    error: false,
    reg: /^[0-9]{10}$/,
    saved: false,
    isLoading: false,
    showDefault: true,
  },
  {
    platform: "Email",
    icon: "https://www.freepnglogos.com/uploads/envelope-png/black-envelope-icon-icons-and-png-backgrounds-26.png",
    title: "Email",
    placeholder: "Enter your email id",
    inputText: "Please check the format - example@domain.com",
    status: true,
    error: false,
    reg: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    saved: false,
    isLoading: false,
    showDefault: true,
  },
  {
    platform: "Portfolio",
    icon: "https://www.freepnglogos.com/uploads/logo-internet-png/logo-internet-icon-website-image-pixabay-10.png",
    title: "Website",
    placeholder: "Enter the website link",
    inputText: "Please enter the website url",
    status: true,
    error: false,
    reg: /^(https?:\/\/)?[a-zA-Z0-9_\-]+(\.[a-zA-Z0-9_\-]+)+([a-zA-Z0-9_\-.,@?^=%&:/~+#]*)?$/,
    saved: false,
    isLoading: false,
    showDefault: true,
  },
  {
    platform: "Twitter",
    icon: "https://www.freepnglogos.com/uploads/twitter-logo-png/twitter-logo-vector-png-clipart-1.png",
    title: "Twitter Profile URL",
    placeholder: "Profile url",
    inputText: "Please enter the profile url",
    status: false,
    error: false,
    reg: /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/[a-zA-Z0-9_]{1,15}\/?$/,
    saved: false,
    isLoading: false,
    showDefault: true,
  },
  {
    platform: "Facebook",
    icon: "https://www.freepnglogos.com/uploads/facebook-logo-13.png",
    title: "Facebook Profile URL",
    placeholder: "Profile url",
    inputText: "Please enter the profile url",
    status: false,
    error: false,
    reg: /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9\.]{1,50}\/?$/,
    saved: false,
    isLoading: false,
    showDefault: true,
  },
  {
    platform: "LinkedIn",
    icon: "https://www.freepnglogos.com/uploads/linkedin-logo-design-30.png",
    title: "LinkedIn Profile URL",
    placeholder: "Profile url",
    inputText: "Please enter the profile url",
    status: false,
    error: false,
    reg: /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]{5,30}\/?$/,
    saved: false,
    isLoading: false,
    showDefault: true,
  },
];

//think API States

export const THUNK_API_STATES = {
  pending: "pending",
  rejected: "rejected",
  fulfilled: "fulfilled",
};

export const TestimonialTabs = {
  WallOfLove: "Wall of Love",
  ALL: "All",
  SELF: "Self",
  PUBLIC: "Public",
};

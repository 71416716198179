import React, { useState } from "react";
import { AddFlex, Gap, PrimaryActionButton } from "../reusableStyles";
import Tabs from "../Tabs";
import { FeaturesKeys } from "../utils";

function ReportsTabHeader({
  featureKey,
  reportsTypeTabIndex,
  setReportsTypeTabIndex,
  handleDownloadAllCustomersReport,
}) {
  const [loading, setLoading] = useState(false);
  const getTabs = () => {
    const arr = [];
    if (featureKey.id !== FeaturesKeys.subscription.id) {
      arr.push({ name: "Summary" });
    }
    // arr.push({ name: "All Sales" });
    return arr;
  };
  return (
    <AddFlex alignItems="center" padding="10px 20px" margin="0px 0 10px 0">
      <Tabs
        selectedTabIndex={reportsTypeTabIndex}
        setSelectedTabIndex={(index) => setReportsTypeTabIndex(index)}
        tabs={getTabs()}
      />
      <Gap />
      <PrimaryActionButton
        onClick={async () => {
          try {
            setLoading(true);
            await handleDownloadAllCustomersReport();
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        }}
      >
        {loading ? "Loading..." : "Export"}
      </PrimaryActionButton>
    </AddFlex>
  );
}

export default ReportsTabHeader;

import React, { useEffect, useRef, useState } from "react";
import { AddFlex, CustomInput, CustomText } from "./reusableStyles";
import { callNonStoreApi } from "./NonStoreApis";
import { isNullOrUndefined, isStringNullOrUndefined } from "./utils";
import useWindowSize from "./Hooks/useWindowSize";
import { CircularProgress } from "@mui/material";
import { primaryActionColor } from "./constants";
import { ContentCopy } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

function CustomUrlComponent({
  width,
  leftText,
  label,
  name,
  urlKey,
  endpoint,
  payloadData,
  margin,
  footerText,
  throwError,
  successCallback,
}) {
  const [_url, setUrl] = useState(urlKey);
  const [apiLoading, setIsApiLoading] = useState(false);
  const [errMsg, setIsErrMsg] = useState(null);
  const debounceTimer = useRef();
  const [shouldPropagateNameToUrl, setShouldPropagateNameToUrl] = useState(
    isStringNullOrUndefined(urlKey)
  );

  useEffect(() => {
    if (shouldPropagateNameToUrl && name.length > 0)
      setUrl(name.toLowerCase().replaceAll(" ", "-"));
  }, [name]);

  const apiCall = async () => {
    try {
      if (isStringNullOrUndefined(endpoint)) {
        return setIsErrMsg("Contact support");
      }
      const payload = isNullOrUndefined(payloadData)
        ? { urlKey: _url }
        : { ...payloadData, urlKey: _url };
      const response = await callNonStoreApi(endpoint, payload);
      if (successCallback) successCallback(_url);
      setIsApiLoading(false);
    } catch (error) {
      throwError(error);
      if (error.response.status === 400) {
        setIsErrMsg("This url is already used");
      }
    } finally {
      setIsApiLoading(false);
    }
  };

  const debounce = (callback, delay) => {
    if (_url === urlKey) {
      return;
    }
    setIsApiLoading(true);
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(callback, delay);
  };

  useEffect(() => {
    if (isNullOrUndefined(_url)) {
      //Initial case where title and urk key both are undefined dont call api
      //Dont show error
      return;
    }
    if (isStringNullOrUndefined(_url)) {
      return setIsErrMsg("Custom URL cannot be empty");
    }
    debounce(apiCall, 2000);
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [_url]);

  const isValidString = (s) => {
    if (s.length === 0) return true;
    return /^[a-zA-Z0-9-]+$/.test(s);
  };

  return (
    <AddFlex margin={margin} gap="8px" flexDirection="column">
      <CustomInput
        leftWidget={
          <CustomText fontSize="13px" color="black">
            {leftText}
          </CustomText>
        }
        leftBackgroundColor="#f9fafb"
        label={label ? label : "Your sharable url"}
        width={width}
        value={_url}
        required
        onChange={(text) => {
          let _text = text.replaceAll(" ", "-");
          if (!isValidString(_text)) return;
          setIsErrMsg(null);
          setShouldPropagateNameToUrl(false);
          setUrl(_text);
        }}
        footerText={footerText}
        widget={
          apiLoading ? (
            <CircularProgress sx={{ color: primaryActionColor }} size={12} />
          ) : (
            <ContentCopy
              onClick={async () => {
                if (isNullOrUndefined(_url) || _url.length === 0) {
                  return;
                }
                try {
                  const response = await navigator.clipboard.writeText(
                    leftText + _url
                  );
                  enqueueSnackbar("Link Copied to clipboard", {
                    variant: "success",
                  });
                } catch (error) {
                  enqueueSnackbar("Error in copying link", {
                    variant: "error",
                  });
                }
              }}
              sx={{
                color:
                  isNullOrUndefined(_url) || _url.length === 0
                    ? "grey"
                    : primaryActionColor,
                fontSize: "16px",
                cursor: "pointer",
              }}
            />
          )
        }
      />
      {errMsg && (
        <CustomText color="red" fontSize="14px">
          {errMsg}
        </CustomText>
      )}
    </AddFlex>
  );
}

export default CustomUrlComponent;

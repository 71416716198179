import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import styled from "styled-components";
import { AddFlex, CustomText, FullPageParent } from "../reusableStyles";
import {
  getFrontendBaseURL,
  isArrayNullOrUndefined,
  isNullOrUndefined,
  toCamelCase,
} from "../utils";
import { callNonStoreApi } from "../NonStoreApis";
import { FetchPageAnalytics, FetchTotalAnalytics } from "../Api/ApiEndPoints";

import Loading from "../Loading";
import { addDays, format, subDays } from "date-fns";
import RevenueChips from "./RevenueChips.js";
import TopCountriesStats from "../Analytics/TopCountriesStats.js";
import TopCitiesStats from "../Analytics/TopCitiesStats.js";
import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import useWindowSize from "../Hooks/useWindowSize.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: flex-start;
`;

const Dropdown = styled.select`
  padding: 10px;
  border-radius: 5px;
  margin-right: 15px;
  border: 1px solid #ccc;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
`;

const Card = styled.div`
  background: white;
  width: 100%;
  border-radius: 8px;
  padding: 15px;
  margin-right: 10px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  background-color: ${(props) => (props.active ? "#2e5dcd" : "white")};
  color: ${(props) => (props.active ? "white" : "#333333")};
`;

const CardTitle = styled.div`
  font-size: 14px;
  margin-left: 10px;
  color: "#252525";
`;

const CardValue = styled.div`
  font-size: 24px;
  margin-right: 10px;
  text-align: right;
`;

function AnalyticsView({ offering }) {
  const { isMobileView } = useWindowSize();
  const [dateRange, setDateRange] = useState(7);
  const [response, setResponse] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [apiLoading, setApiLoading] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const { userName, userId } = useSelector((state) => state.user.apiUserData);

  useEffect(() => {
    fetchDataAndSetChartData(activeCardIndex, dateRange);
  }, []);

  const handleDateChange = (event) => {
    const newDateRange = event.target.value;
    setDateRange(newDateRange);
    setApiLoading(true);
    fetchDataAndSetChartData(activeCardIndex, newDateRange);
  };

  const getApi = async (dateRange) => {
    if (isNullOrUndefined(offering)) {
      return await callNonStoreApi(FetchTotalAnalytics, {
        daysBefore: dateRange,
        pagePath: `https://${userName}.growezy.club/`, //getFrontendBaseURL(userName),
      });
    } else {
      return await callNonStoreApi(FetchPageAnalytics, {
        offeringId: offering.id,
        pagePath: `https://${userName}.growezy.club/${offering.urlKey}`,
        daysBefore: dateRange,
        ctas: offering.ctas.map((cta) => cta.type),
      });
    }
  };

  const fetchDataAndSetChartData = async (cardIndex, dateRange) => {
    try {
      const response = await getApi(dateRange);
      if (response) {
        setError(null);
        setResponse(response.data);
        handleCardClick(cardIndex, response.data);
      }
    } catch (error) {
      setError("Something went wrong. Please try again later. " + error);
      console.log(error);
    } finally {
      setLoading(false);
      setApiLoading(false);
    }
  };

  const getCategoryAllClickStats = () => {
    const arr = response.buttonClicks.filter((item) => item.category === "all");
    if (isArrayNullOrUndefined(arr)) return [];
    return arr.flatMap((item) => item.dates);
  };

  const getChartDataAtCard = (cardIndex, response) => {
    if (isNullOrUndefined(response)) return [];
    if (cardIndex === 0) {
      return response.screenPageViews;
    } else if (cardIndex === 1 && isNullOrUndefined(offering)) {
      return getCategoryAllClickStats();
    } else {
      return response.buttonClicks.filter(
        (item) =>
          item.category === getCTATypeFromName(ctaActions[cardIndex - 1])
      )?.dates;
    }
  };

  const getTypesOfCtas = () => {
    const arr = [];
    if (isNullOrUndefined(offering.ctas)) return [];
    offering.ctas.forEach((element) => {
      arr.push(element.name);
    });
    return arr;
  };

  const ctaActions = isNullOrUndefined(offering)
    ? ["CTA Clicks"]
    : getTypesOfCtas();
  var cards = ["Page Views"];
  cards.splice(1, 0, ...ctaActions);

  const getCTATypeFromName = (name) => {
    const cta = offering.ctas.find((cta) => cta.name === name);
    return cta.type;
  };

  const getAggregatedCount = (index) => {
    if (isNullOrUndefined(response)) return 0;
    return response.totalStats.find((item) =>
      index === 0
        ? item.eventName === "page_view"
        : index === 1 && isNullOrUndefined(offering)
        ? item.eventName === "cta_button_click"
        : item.eventName === getCTATypeFromName(ctaActions[index - 1])
    )?.total;
  };

  const handleCardClick = (cardIndex, response) => {
    // Placeholder function to mimic API call
    setActiveCardIndex(cardIndex);
    setChartData(getChartDataAtCard(cardIndex, response));
  };

  function getFinalChartData() {
    const endDate = new Date();
    const startDate = subDays(endDate, dateRange - 1);

    let currentDate = new Date(startDate);
    const filledData = [];

    while (currentDate < endDate) {
      const formattedDate = format(currentDate, "dd MMM yyyy");

      const existingData = isNullOrUndefined(chartData)
        ? null
        : chartData.find(
            (d) =>
              new Date(d.date).getTime() === new Date(formattedDate).getTime()
          );
      filledData.push(
        existingData || { date: formattedDate, total: 0, unique: 0 }
      );

      currentDate = addDays(currentDate, 1);
    }
    return filledData;
  }

  return (
    <FullPageParent>
      {loading ? (
        <Loading size="50" />
      ) : !isNullOrUndefined(error) ? (
        <CustomText>{error}</CustomText>
      ) : (
        <Container
          style={{
            padding: isNullOrUndefined(offering) ? 0 : "20px",
          }}
        >
          <RevenueChips
            cards={cards}
            onClick={(index) => handleCardClick(index, response)}
            activeCardIndex={activeCardIndex}
            getAggregatedCount={getAggregatedCount}
            pageLevel={!isNullOrUndefined(offering)}
          />
          <AddFlex
            backgroundColor="white"
            flexDirection="column"
            padding="10px"
            style={{
              borderRadius: "10px",
              marginBottom: "15px",
              marginTop: "10px",
              boxShadow: "0px 0px 10px 1px #E7E9ED",
            }}
          >
            <AddFlex justify="space-between" alignItems="center">
              <CustomText fontSize="18px" color="#000" fontWeight="500">
                {cards[activeCardIndex]} Analytics
              </CustomText>
              <Select
                value={dateRange}
                displayEmpty
                onChange={handleDateChange}
                disabled={apiLoading}
                size="small"
                sx={{ margin: "5px 5px 0 0" }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={2}>Yesterday</MenuItem>
                <MenuItem value={7}>Last 7 days</MenuItem>
                <MenuItem value={14}>Last 14 days</MenuItem>
                <MenuItem value={90}>Last 90 days</MenuItem>
              </Select>
            </AddFlex>
            <div
              style={{
                position: "relative",
                marginTop: "10px",
              }}
            >
              <ResponsiveContainer height={300} style={{ marginLeft: "-20px" }}>
                <LineChart
                  data={getFinalChartData()}
                  margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
                >
                  <CartesianGrid stroke="#ECEDED" strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    axisLine={false}
                    fontSize={"14px"}
                    tickMargin={10}
                    tick={{ fill: "#666" }}
                  />
                  <YAxis
                    axisLine={false}
                    fontSize={"14px"}
                    tick={{ fill: "#666" }}
                    tickMargin={10}
                  />
                  <Tooltip
                    contentStyle={{ fontSize: "14px" }}
                    formatter={(value, name) => [value, toCamelCase(name)]}
                  />
                  <Legend formatter={(value) => toCamelCase(value)} />
                  <Line
                    type="monotone"
                    dataKey="total"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="unique" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
              {apiLoading && (
                <AddFlex
                  style={{
                    // backgroundColor: "rgb(0,0,0,0.6)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "300px",
                  }}
                  alignItems="center"
                  justify="center"
                >
                  <Loading size="50" />
                </AddFlex>
              )}
            </div>
          </AddFlex>
          <AddFlex
            marginTop={isMobileView() ? "10px" : "20px"}
            flexDirection={isMobileView() && "column"}
            gap={isMobileView() ? "15px" : "30px"}
          >
            <TopCountriesStats
              isMobileView={isMobileView()}
              totalViews={
                response?.totalStats.find(
                  (item) => item.eventName === "page_view"
                )?.total
              }
              countryStats={response?.countryStats}
            />
            <TopCitiesStats
              isMobileView={isMobileView()}
              totalViews={
                response?.totalStats.find(
                  (item) => item.eventName === "page_view"
                )?.total
              }
              cityStats={response?.cityStats}
            />
          </AddFlex>
        </Container>
      )}
    </FullPageParent>
  );
}

export default AnalyticsView;

import { createSlice } from "@reduxjs/toolkit";
import { FeaturesKeys, isNullOrUndefined } from "../utils";
import { addDays } from "date-fns";

const getInitialReportFilterData = (featureName) => {
  return {
    summary: {
      start: addDays(new Date(), -7),
      end: new Date(),
      filterData: {
        filterBy: {
          isPublished: "Both",
          groupBy: featureName,
          // dateRange: DateFilters.AllTime,
        },
        selectedItems: null,
      },
      selectedGroupByIndex: 0,
    },
    allSales: {
      start: addDays(new Date(), -7),
      end: new Date(),
      filterData: {
        filterBy: {
          isPublished: "Both",
          groupBy: featureName,
          // dateRange: DateFilters.AllTime,
        },
        selectedItems: null,
      },
    },
  };
};

const getReportFilterInitialData = () => {
  return {
    [FeaturesKeys.course.id]: getInitialReportFilterData(
      FeaturesKeys.course.name
    ),
    [FeaturesKeys.event.id]: getInitialReportFilterData(
      FeaturesKeys.event.name
    ),
    [FeaturesKeys.travel.id]: getInitialReportFilterData(
      FeaturesKeys.travel.name
    ),
    [FeaturesKeys.webinar.id]: getInitialReportFilterData(
      FeaturesKeys.webinar.name
    ),
    [FeaturesKeys.subscription.id]: getInitialReportFilterData(
      FeaturesKeys.subscription.name
    ),
  };
};
const initialState = {
  data: {
    pagesTabSelectedIndex: 0,
    reportFilters: getReportFilterInitialData(),
    requestedUser: null,
  },
};

const setParentLayoutScroll = (state, action) => {
  const parentLayoutScroll = action.payload;
  state.data.parentLayoutScroll = parentLayoutScroll;
};

const setRevenueStats = (state, action) => {
  const { featureId, stats } = action.payload;
  if (isNullOrUndefined(state.data.revenueStats)) {
    state.data.revenueStats = {
      [featureId]: stats,
    };
  } else state.data.revenueStats[featureId] = stats;
};

const setSelectedCustomer = (state, action) => {
  state.data.selectedCustomer = action.payload;
};

const setUserCalendars = (state, action) => {
  state.data.calendars = action.payload;
};

const setPagesTabPath = (state, action) => {
  state.data.pagesTabPath = action.payload;
};

const requestedUser = (state, action) => {
  state.data.requestedUser = action.payload;
};

const setLoginId = (state, action) => {
  state.data.loginId = action.payload;
};

const setReportsTabPath = (state, action) => {
  state.data.reportsTabPath = action.payload;
};

const setPayoutSaleData = (state, action) => {
  state.data.payoutsSaleData[action.payload.sale.saleId] = action.payload.sale;
};

const setResetReportsFilters = (state, action) => {
  state.data.reportFilters = getReportFilterInitialData();
};

const resetPricingIndexes = (state, action) => {
  if (isNullOrUndefined(state.data.resetPricingIndexes)) {
    state.data.resetPricingIndexes = true;
  } else state.data.resetPricingIndexes = !state.data.resetPricingIndexes;
};

const handleSortScheduleDetails = (state, action) => {
  if (isNullOrUndefined(state.data.sortScheduleDetails)) {
    state.data.sortScheduleDetails = true;
  } else state.data.sortScheduleDetails = !state.data.sortScheduleDetails;
};

const setReportsFilters = (state, action) => {
  state.data.reportFilters[action.payload.tabName][action.payload.tabKey] = {
    ...state.data.reportFilters[action.payload.tabName][action.payload.tabKey],
    [action.payload.key]: action.payload.value,
  };
};

const setPagesTabIndex = (state, action) => {
  state.data.pagesTabSelectedIndex = action.payload;
};

const appTempSlice = createSlice({
  name: "appTemp",
  initialState,
  reducers: {
    setPagesTabPathAction: setPagesTabPath,
    setPayoutSaleDataAction: setPayoutSaleData,
    resetPricingIndexesAction: resetPricingIndexes,
    setReportsTabPathAction: setReportsTabPath,
    setReportsFiltersAction: setReportsFilters,
    setResetReportsFiltersAction: setResetReportsFilters,
    sortScheduleDetailsAction: handleSortScheduleDetails,
    requestedUserAction: requestedUser,
    setLoginUserIdAction: setLoginId,
    setRevenueStatsAction: setRevenueStats,
    setPagesTabIndexAction: setPagesTabIndex,
    setUserCalendarsAction: setUserCalendars,
    setParentLayoutScrollAction: setParentLayoutScroll,
    setSelectedCustomerAction: setSelectedCustomer,
  },
});

export const {
  setPagesTabPathAction,
  setPayoutSaleDataAction,
  resetPricingIndexesAction,
  setReportsTabPathAction,
  setReportsFiltersAction,
  setResetReportsFiltersAction,
  sortScheduleDetailsAction,
  requestedUserAction,
  setLoginUserIdAction,
  setRevenueStatsAction,
  setPagesTabIndexAction,
  setUserCalendarsAction,
  setParentLayoutScrollAction,
  setSelectedCustomerAction,
} = appTempSlice.actions;
export default appTempSlice.reducer;
